<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center">
        <!-- Nickname -->
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="player.nickname"
            hint="Il tuo nickname di TS"
            :rules="rules.nickname"
            persistent-hint
            filled
            label="Nickname"
          />
        </v-col>
        <v-spacer />
        <!-- Sezione -->
        <v-col cols="12" lg="4">
          <v-select
            v-model="player.sezione"
            item-text="name"
            item-value="icon"
            :return-object="false"
            :items="sezioni"
            filled
            :rules="rules.sezione"
            hide-selected
            hint="Seleziona la sezione di appartenza"
            persistent-hint
          >
            <template v-slot:selection="data">
              <v-list-item-avatar>
                <v-img
                  :src="require(`@/assets/gameicons/${data.item.icon}.svg`)"
                  aspect-ratio="1"
                  height="24"
                  contain
                />
              </v-list-item-avatar>
              <v-list-item-content>
                {{ data.item.name }}
              </v-list-item-content>
            </template>
            <template slot="item" slot-scope="data">
              <v-list-item-avatar>
                <v-img
                  :src="require(`@/assets/gameicons/${data.item.icon}.svg`)"
                  aspect-ratio="1"
                  height="24"
                  contain
                />
              </v-list-item-avatar>
              <v-list-item-content>
                {{ data.item.name }}
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
        <v-spacer />
        <!-- Ore -->
        <v-col cols="12" lg="4">
          <v-select
            v-model="player.tempo"
            item-text="text"
            item-value="value"
            :return-object="false"
            :items="tempo"
            :rules="rules.tempo"
            filled
            hide-selected
            hint="Quanta esperienza hai nel gioco?"
            persistent-hint
          >
          </v-select>
        </v-col>
        <v-spacer />
        <!-- Ruolo -->
        <v-col cols="12" sm="12" lg="12">
          <v-row>
            <v-col cols="12" sm="12" :md="player.ruolo === 'player' ? 6 : 12">
              <v-select
                v-model="player.ruolo"
                item-text="text"
                item-value="value"
                :return-object="false"
                :items="ruolo"
                :rules="rules.ruolo"
                filled
                hide-selected
                hint="Qual'e il tuo ruolo?"
                persistent-hint
              >
              </v-select>
            </v-col>
            <v-spacer />
            <v-col
              v-if="player.ruolo === 'player'"
              cols="12"
              sm="12"
              md="6"
              align-self="center"
            >
              <v-select
                v-model="player.comp"
                item-text="text"
                item-value="value"
                :return-object="false"
                :items="comp"
                filled
                hide-selected
                hint="Giochi in ambito competitivo?"
                persistent-hint
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col class="mb-6">
        <v-btn
          color="primary"
          elevation="2"
          :disabled="!valid"
          x-large
          block
          @click="submit"
        >
          Continua
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      valid: false,
      sezioni: [
        { icon: "r6", name: "Rainbow Six Siege" },
        { icon: "valorant", name: "Valorant" },
        { icon: "sim", name: "Sim Racing" },
        { icon: "fifa", name: "Fifa" },
        { icon: "lol", name: "League of Legends" },
        { icon: "other", name: "Altri Giochi" }
      ],
      tempo: [
        { value: "00-06", text: "Da 0 a 6 Mesi" },
        { value: "06-12", text: "Da 6 mesi a 1 Anno" },
        { value: "12-24", text: "Da 1 a 2 Anni" },
        { value: "24+", text: "Più di 2 Anni" }
      ],
      ruolo: [
        { value: "player", text: "Giocatore" },
        { value: "staff", text: "Staff" },
        { value: "fan", text: "Fan" }
      ],
      comp: [
        { value: true, text: "Competitivo" },
        { value: false, text: "Non competitivo" }
      ],
      rules: {
        nickname: [v => !!v || "Inserisci il tuo nickname"],
        sezione: [v => !!v || "Seleziona la sezione"],
        tempo: [v => !!v || "Seleziona una opzione"],
        ruolo: [v => !!v || "Seleziona una opzione"]
      }
    };
  },
  methods: {
    submit: async function() {
      await this.$refs.form.validate();
      if (this.valid) {
        this.$store.commit("MutateStep", 2);
        this.$router.push("/step2");
      }
    }
  },
  computed: {
    player: {
      get() {
        return this.$store.state.tesseramento.player;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
