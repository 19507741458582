<template>
  <div class="home">
    <PlayerForm />
  </div>
</template>

<script>
import PlayerForm from "@/components/Forms/Player";
export default {
  name: "Player",
  components: {
    PlayerForm
  }
};
</script>
