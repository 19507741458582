<template>
  <v-container>
    <v-row class="py-6" justify="center">
      <h2 class="justify-center"><AccountHeart /> Modulo di tesseramento</h2>
    </v-row>
  </v-container>
</template>
<script>
import AccountHeart from "@/components/Icons/AccountHeart";
export default {
  components: {
    AccountHeart
  }
};
</script>
