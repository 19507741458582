import colors from "vuetify/lib/util/colors";

export default {
  dark: {
    primary: colors.red.darken2,
    // secondary: colors.grey.darken1,
    // accent: colors.purple.darken4,
    error: colors.red.accent4,
    disabled: colors.grey.darken2
  }
};
