<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col
        v-for="item in steps"
        :key="item.step"
        :cols="calculateColumns(item.step)"
        :class="cssClasses(item.step)"
        class=""
      >
        <span class="number" :style="numberStyle(item.step)">{{
          item.step
        }}</span>
        <span v-if="item.step === step" class="text">{{ item.title }}</span>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>
<script>
import theme from "@/plugins/vuetify-theme";
export default {
  data() {
    return {
      steps: [
        { step: 1, title: "Player" },
        { step: 2, title: "Tesseramento" },
        { step: 3, title: "Pagamento" }
      ]
    };
  },
  computed: {
    step: {
      get() {
        return this.$store.state.step;
      }
    }
  },
  methods: {
    calculateColumns(step) {
      const stepNumber = this.steps.length;
      const columnMax = 9;
      const hiddenColumnWidth = 1;
      if (this.step == step) {
        const hiddenColumnToRemove = (stepNumber - 1) * hiddenColumnWidth;
        return columnMax - hiddenColumnToRemove;
      }
      return hiddenColumnWidth;
    },
    cssClasses(step) {
      let css = ["step"];
      if (this.step === step) {
        css.push("show");
      }
      return css;
    },
    numberStyle(itemStep) {
      let style = {
        background: theme.dark.primary
      };
      if (this.step < itemStep) {
        style.background = theme.dark.disabled;
      }
      return style;
    }
  }
};
</script>
<style lang="scss" scoped>
.step {
  user-select: none;

  &.show {
    .number {
      margin-right: 10px;
    }
  }
  .number {
    text-align: center;
    display: inline-block;
    line-height: 24px;
    height: 24px;
    width: 24px;
    margin-right: 0px;
    border-radius: 100px;
    &.gray {
      background: #555;
    }
  }
}
</style>
