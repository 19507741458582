import store from "@/store";

function validateStep1() {
  let step1 = store.state.tesseramento.player;
  return validate(step1);
}
function validateStep2() {
  let step2 = store.state.tesseramento.personali;
  return validate(step2);
}
function validate(obj, valid = true) {
  if (!valid) {
    return false;
  }
  let isValid = true;
  for (let [, v] of Object.entries(obj)) {
    if (v === "" || v === undefined) {
      isValid = false;
      break;
    }
    if (typeof v === "object") {
      isValid = validate(v, isValid);
    }
  }
  return isValid;
}
export { validateStep1, validateStep2 };
