<template>
  <v-icon :style="style">{{ svgPath }}</v-icon>
</template>

<script>
import { mdiAccountHeart } from "@mdi/js";
import theme from "@/plugins/vuetify-theme";
export default {
  data: () => ({
    svgPath: mdiAccountHeart,
    style: {
      color: theme.dark.primary
    }
  })
};
</script>
