<template>
  <v-app>
    <v-main>
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-col col="12" sm="10" md="8" lg="8" xl="8" class="col-transition">
            <HeadHeader />
            <v-card elevation="12" rounded="lg" class="px-5 py-2">
              <Header />
              <Stepper v-if="stepVisible" />
              <router-view />
            </v-card>
            <Footer />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Stepper from "@/components/Stepper";
import HeadHeader from "@/components/HeadHeader";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    Stepper,
    HeadHeader
  },
  computed: {
    stepVisible: {
      get() {
        if (typeof this.$store.state.step === "number") return true;
        return false;
      }
    },
    tesseramento: {
      get() {
        return this.$store.state.tesseramento;
      }
    }
  }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap");
* {
  font-family: "Poppins", sans-serif !important;
}
html .col-transition {
  transition: max-width 0.4s, width 0.4s;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
// Bg
#app {
  background: url("~@/assets/bg.png") center;
  background-size: cover;
}
// Fix Vuetify
.v-list-item__content {
  flex: 1 1 100% !important;
}
</style>
