/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import Player from "@/views/Player.vue";
import store from "@/store";
import { validateStep1, validateStep2 } from "@/utils/form-validation";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Tesseramento - Step 1",
    component: Player,
    meta: {
      step: 1
    }
  },
  {
    path: "/step2",
    name: "Tesseramento - Step 2",
    component: () =>
      import(/* webpackChunkName: "Personali" */ "@/views/Personali.vue"),
    meta: {
      step: 2
    }
  },
  {
    path: "/step3",
    name: "Tesseramento - Step 3",
    component: () =>
      import(/* webpackChunkName: "Pagamento" */ "@/views/Pagamento.vue"),
    meta: {
      step: 3
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  const nextPageName = to.matched
    .slice()
    .reverse()
    .find(r => r.name).name;
  document.title = nextPageName;
  if ("step" in to.meta) {
    store.commit("MutateStep", to.meta.step);
  } else {
    store.commit("MutateStep", false);
  }
  const step1 = validateStep1();
  const step2 = validateStep2();
  if (!step1 && to.path === "/step2") {
    next("/");
    return;
  }
  if (!(step2 && step1) && to.path === "/step3") {
    next("/step2");
    return;
  }
  next();
});
export default router;
