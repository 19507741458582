import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import R6 from "@/components/GameIcons/R6.vue";
import themes from "./vuetify-theme";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      ...themes
    }
  },
  icons: {
    values: {
      r6: {
        component: R6,
        props: {
          name: "game-r6"
        }
      }
    }
  }
});
